<template>
  <div class="mawj-custom-tab user-main-tab-st mt-6">
    <b-tabs content-class="mt-3" pills card>
      <b-tab title="Timeline" active>
        <timelineTab :user_id="data._id" />
      </b-tab>
      <b-tab title="About">
        <aboutMainTab  @reload="$emit('reload')" :data="userData" />
      </b-tab>
      <b-tab title="Social media">
        <socialMediaTab @reload="$emit('reload')" :userData="userData" />
      </b-tab>
      <b-tab title="Awards">
        <awardTab :userId="data._id" />
      </b-tab>
      <b-tab title="Certificates">
        <certificateTab :userId="data._id" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { userMethods } from "@/state/helpers";
import aboutMainTab from "./mainTab/about.vue";
import socialMediaTab from "./mainTab/socialMedia.vue";
import awardTab from "./mainTab/award.vue";
import certificateTab from "./mainTab/certificates.vue";
import timelineTab from "./mainTab/timeline.vue";
export default {
  props: ["data"],
  components: {
    aboutMainTab,
    socialMediaTab,
    awardTab,
    certificateTab,
    timelineTab,
  },
  data() {
    return {
      userData: null,
    };
  },
  created() {
    this.getUser().then((res) => {
      this.userData = res;
    });
  },
  methods: {
    ...userMethods,
  },
};
</script>

<style>
.user-main-tab-st .card-header {
  padding: 10px;
}
.user-main-tab-st .tab-pane {
  padding: 0px;
}
</style>
