<template>
  <div>
    <MawjBtn class="float-right" label="Edit" @click="edit = true" />
    <div class="mb-3">
      <h6>Contact Information</h6>
      <table>
        <tr>
          <td class="pr-5">Phone</td>
          <td>
            {{ data.country_code }}
            {{ data.phone_number }}
          </td>
        </tr>
        <tr>
          <td class="pr-5">Email</td>
          <td>{{ data.email }}</td>
        </tr>
        <tr class="pr-5">
          <td>Nationality</td>
          <td>
            {{ (data.nationality && data.nationality.name) || "Not Provided" }}
          </td>
        </tr>
      </table>
    </div>

    <editUser @reload="$emit('reload')" :open="edit" @hide="edit = false" :userData="data" :countries="countries" />
  </div>
</template>

<script>
import MawjBtn from "@/components/common/mawjBtn.vue";
import editUser from "../editUser.vue";
import { countries } from "@/config/api/countries";
export default {
  props: ["data"],
  components: {
    MawjBtn,
    editUser,
  },
  created() {
    this.loadCountries();
  },
  data() {
    return {
      edit: false,
      countries: [],
    };
  },
  methods: {
    loadCountries() {
      const api = countries.get;
      this.generateAPI(api)
        .then((res) => {
          this.countries = res.data.countries.map((item) => {
            return {
              label: item.name,
              value: item._id,
            };
          });
        })
        .catch((err) => {
          console.error(`Error ${err.response.message}`);
        });
    },
  },
};
</script>
