<template>
  <div style="width: 240px; margin-left: auto; position: relative">
    <button class="subscription-edit-btn" v-b-modal.subscription-plan-modal>
      Edit
    </button>
    <BlockHeader title="Subscription Plan" />
    <div>Plan Level : {{ data.plan.name }}</div>

    <b-modal
      id="subscription-plan-modal"
      hide-footer
      size="sm"
      title="Subscription plan edit"
    > 
      <FormulateForm :values="formValues" @submit="submitHandler">
        <FormulateInput name="plan_level" type="select" :options="levelList" />
        <div class="d-flex justify-content-end">
          <FormulateInput
            type="submit"
            :label="submitting ? 'Changing...' : 'Update Plan'"
          />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import BlockHeader from "./blockHeader.vue";
import { levels } from "@/config/api/levels";
import { users } from "@/config/api/users";
import Swal from "sweetalert2";

export default {
  components: {
    BlockHeader,
  },
  created() {
    this.getPlanLevels();
  },
  data() {
    return {
      levelList: [],
      planListLoading: false,
      formValues: {
        plan_level: this.data.plan._id,
      },
      submitting: false,
    };
  },
  props: ["data"],
  methods: {
    getPlanLevels() {
      this.planListLoading = true;
      const api = levels.getAll;

      api.params = {
        user_type: "company",
      };

      this.generateAPI(api)
        .then((res) => {
          this.levelList = res.data.plans.map((item) => {
            return {
              value: item._id,
              label: item.name,
            };
          });
        })
        .finally(() => {
          this.planListLoading = false;
        });
    },
    async submitHandler(data) {
      const api = users.setPlanLevel;
      api.id = this.data.id;
      this.submitting = true;
      api.data = data;
      this.generateAPI(api)
        .then((res) => {
          Swal.fire(
            "Subscription plan",
            "User subscription changed successfully.",
            "success"
          );
          this.$emit("reload");
          this.$bvModal.hide("subscription-edit-btn");

        })
        .catch((err) => {
          Swal.fire(
            "Subscription plan",
            "User subscription plan is not changed!",
            "error"
          );
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style scoped>
.subscription-edit-btn {
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: -8px;
  font-size: 14px;
  color: #586e84;
}
.subscription-edit-btn:hover {
  color: #000;
}
</style>
