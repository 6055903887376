<template>
  <div>
    <div class="text-right mb-2" style="margin-top: -18px">
      <MawjBtn @click="openCertificateModalFunc" label="Add" />
    </div>

    <div v-if="loading">Loading...</div>
    <div v-else>
      <div v-if="certificateInfo.length">
        <div
          class="d-flex justify-content-between align-self-center mb-3"
          v-for="item in certificateInfo"
          :key="item._id"
        >
          <div>
            <h6 class="mb-0">
              <img
                src="@/assets/images/award.svg"
                class="pr-1"
                alt=""
                srcset=""
              />{{ item.title }}
            </h6>
            <div style="font-size: 12px">
              <p class="mb-0 pl-3">
                {{ item.description }}
              </p>
              <span class="pl-3">
                {{ moment(item.date_received).format("YYYY") }}
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <fileUploader
              :api="certificates.updateUserCertificateAdmin"
              :urlLink="[item.certificate_document]"
              :putKey="item._id"
              uploadKey="certificate_document"
              :formData="{}"
              @uploaded="loadInfo"
            />
            <MawjBtn
              @click="updateCertificate(item)"
              label="Edit"
              :noBorder="true"
              class="mt-0 mx-4"
            />
          </div>
        </div>
      </div>
      <div v-else>No Award available for this user</div>
    </div>
    <certificateModal
      :open="openCertificateModal"
      :id="userId"
      @reload="loadInfo"
      @hide="
        openCertificateModal = false;
        selectedCertificate = null;
      "
      :selectedCertificate="selectedCertificate"
    />
  </div>
</template>

<script>
import MawjBtn from "@/components/common/mawjBtn.vue";
import fileUploader from "../fileUploader.vue";
import { certificates } from "@/config/api/certificates";
import certificateModal from "../certificateModal.vue";

export default {
  props: ["userId"],
  components: {
    MawjBtn,
    fileUploader,
    certificateModal,
  },
  created() {
    this.loadInfo();
  },
  data() {
    return {
      certificateInfo: null,
      loading: false,
      openCertificateModal: false,
      selectedCertificate: null,
      certificates,
    };
  },
  methods: {
    loadInfo() {
      this.loading = true;
      const api = certificates.getAllForUserById;
      api.params = { id: this.userId };
      this.generateAPI(api)
        .then((res) => {
          this.certificateInfo = res.data.userCertificates;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openCertificateModalFunc() {
      this.openCertificateModal = true;
    },
    updateCertificate(item) {
      this.selectedCertificate = item;
      this.openCertificateModal = true;
    },
  },
};
</script>
