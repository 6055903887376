<template>
  <div class="px-4 py-2">
    <FormulateForm :values="formValues" @submit="submitProjectClime">
      <FormulateInput type="text" name="role" label="Role" />
      <FormulateInput type="number" name="duration" label="Project Duration" />
      <FormulateInput
        :options="
          stages.map((item) => {
            return {
              value: item._id,
              label: item.name,
            };
          })
        "
        type="select"
        multiple="true"
        name="stages"
        v-model="formValues.stages"
        placeholder="Select stages"
        label="Please choose stages"
        help="Hold down the Ctrl (Windows) / Command (Mac) button to select multiple options."
      />
      <FormulateInput type="textarea" name="comments" label="Comments" />
      <div class="d-flex justify-content-end">
        <FormulateInput
          type="submit"
          :label="performAction ? 'Updating...' : 'Update Project'"
        />
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { projects } from "../../config/api/projects";
import { getFormData } from "@/utils/createForm";
export default {
  data() {
    return {
      formValues: {
        stages: this.userData.team_members[0].stages,
        role: this.userData.role,
        duration:this.userData.team_members[0].duration,
      },
      performAction: false,
    };
  },
  props: ["companiesData", "stages", "userData"],
  methods: {
    submitProjectClime(val) {
      const data = {
        ...val,
        claim_id: this.userData.team_members[0]._id,
        project_id: this.userData._id,
      };
      const api = projects.editClaimProject;
      api.data = getFormData(data);
      this.performAction = true;
      this.generateMultipartAPI(api)
        .then(() => {
          this.$bvToast.toast("Project claim updated successfully!", {
            title: "Edit",
            variant: "success",
            solid: true,
          });
          this.$emit("reload");
        })
        .catch((err) => {
          this.$bvToast.toast("Project claim is not updated !", {
            title: "Edit",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
};
</script>
