<template>
  <b-modal
    id="edit-user-modal"
    hide-footer
    @hidden="$emit('hide')"
    title="Edit user"
  >
    <div>
      <FormulateForm :values="formValues" @submit="submitCertificate">
        <FormulateInput
          type="text"
          name="name"
          label="Name"
          validation="required"
        />
        <FormulateInput
          type="email"
          name="email"
          label="Email"
          validation="required"
        />
        <div class="row">
          <div class="col-6">
            <FormulateInput
              type="tel"
              name="country_code"
              label="Country code"
              placeholder="eg:971"
            />
          </div>
          <div class="col-6">
            <FormulateInput
              type="tel"
              name="phone_number"
              label="Phone number"
              placeholder="eg:5xxxxxxxx"
            />
          </div>
        </div>
        <div class="mt-3">
          <FormulateInput
            type="select"
            name="nationality"
            label="Nationality"
            :options="countries"
          />
        </div>

        <div class="text-right mt-4 mb-2">
          <FormulateInput
            type="submit"
            :label="sending ? 'Sending...' : 'Update user'"
          />
        </div>
      </FormulateForm>
    </div>
  </b-modal>
</template>

<script>
import { users } from "@/config/api/users";

export default {
  props: ["open", "id", "userData", "countries"],
  watch: {
    open(val) {
      if (val) {
        this.$bvModal.show("edit-user-modal");
      }
    },
    userData: {
      handler(val) {
        this.formValues = {
          name: val.name,
          email: val.email,
          country_code: val.country_code,
          phone_number: val.phone_number,
          nationality:val.nationality._id
        };
      },
      immediate: true,
    },
  },
  data() {
    return {
      formValues: {
        name: null,
        email: null,
        country_code: null,
        phone_number: null,
        nationality: null,
      },
      sending: false,
    };
  },
  methods: {
    submitCertificate(val) {
      const api = users.update;
      api.id = this.userData._id;
      api.data = val;
      this.sending = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Used details updated!", {
            title: "User Details",
            variant: "success",
            solid: true,
          });
          this.$emit("reload");
          this.$bvModal.hide("edit-user-modal");
        })
        .catch((err) => {
          this.$bvToast.toast("Used details is not updated!", {
            title: "User Details",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
};
</script>
