<template>
  <Layout bgColor="#fff">
    <div class="text-center" v-if="loading">
      <b-spinner class="m-1" type="grow" label="Spinning"></b-spinner>
      <b-spinner class="m-1" type="grow" label="Spinning"></b-spinner>
      <b-spinner class="m-1" type="grow" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div
        class="d-flex justify-content-around flex-wrap"
        style="background-color: #fff"
      >
        <div>
          <ImageEditor
            @updated="loadInfo"
            :url="userInfo.cover_image"
            :id="userInfo._id"
          />
          <Expert :data="userInfo" @reload="loadInfo"/>
        </div>
        <div style="min-width: 60%;padding:12px">
          <NameDetails @reload="loadInfo" />
          <MainTab @reload="loadInfo" class="mb-5" :data="userInfo" />
        </div>
        <div>
          <FileAttachment :data="userInfo" @reload="loadInfo"/>
          <SubscriptionPlan
            :data="{ id: userInfo._id, plan: userInfo.plan_level }"
            class="mt-5"
            @reload="loadInfo"
          />
        </div>
      </div>
      <div class="col-12">
        <Projects :data="userInfo" class="mt-5" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import ImageEditor from "@/components/userNew/imageEditor.vue";
import NameDetails from "@/components/userNew/NameDetails.vue";
import FileAttachment from "@/components/userNew/fileAttachment.vue";
import SubscriptionPlan from "@/components/userNew/subscriptionPlan.vue";
import Projects from "@/components/userNew/projects";
import MainTab from "@/components/userNew/mainTab";
import Expert from "@/components/userNew/expert";
import { users } from "@/config/api/users";
import { userMethods } from "@/state/helpers";
export default {
  components: {
    Layout,
    ImageEditor,
    NameDetails,
    FileAttachment,
    SubscriptionPlan,
    Projects,
    MainTab,
    Expert,
  },
  created() {
    if (this.$route.query.id) {
      this.userId = this.$route.query.id;
      this.loadInfo();
    } else {
      throw "user_id is missing!";
    }
  },
  data() {
    return {
      userInfo: null,
      loading: false,
      userId: null,
    };
  },

  methods: {
    ...userMethods,
    loadInfo() {
      const api = users.getById;
      api.id = this.userId;
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.userInfo = res.data.user;
          this.selectUser(this.userInfo);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
