<template>
  <div style="width: 240px; margin-left: auto">
    <BlockHeader title="Attachments" />
    <label> National ID </label>
    <fileUploader
      :urlLink="[data.national_id_front]"
      :api="users.uploadDocs"
      uploadKey="national_id_front"
      :formData="{ userId: this.data._id }"
      @uploaded="$emit('reload')"
    />
    <fileUploader
      :urlLink="[data.national_id_back]"
      :api="users.uploadDocs"
      uploadKey="national_id_back"
      :formData="{ userId: this.data._id }"
      @uploaded="$emit('reload')"
    />
    <label class="mt-3"> Additional Documents </label>
    <fileUploader
      :urlLink="additional_docs"
      :api="users.uploadDocs"
      uploadKey="additional_document"
      @uploaded="loadAdditionalDocs()"
      :formData="{ userId: this.data._id }"
    />
  </div>
</template>

<script>
import BlockHeader from "./blockHeader.vue";
import fileUploader from "./fileUploader.vue";
import { users } from "@/config/api/users";
export default {
  props: ["data"],
  components: {
    BlockHeader,
    fileUploader,
  },
  created() {
    this.loadAdditionalDocs();
  },
  data() {
    return {
      users,
      additional_docs: [],
    };
  },
  methods: {
    loadAdditionalDocs() {
      const api = users.getAdditionalDocs;
      api.id = this.data._id;
      this.generateAPI(api).then((res) => {
        this.additional_docs = res.data.docs.map((item) => item.doc);
      });
    },
  },
};
</script>
