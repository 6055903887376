<template>
  <div>
    <span class="mawj-uploader">
      <div
        v-for="link in url"
        :key="link"
        style="
          width: 120px;
          display: block;
          margin: 8px 0px;
          padding-top: 4px;
          padding-bottom: 4px;
          border-radius: 4px;
          transition: all 0;
          border: 1px solid rgb(88, 110, 132);
          position: relative;
        "
      >
        <a :href="link" target="_blank">
          <img
            :src="link ? link : require('@/assets/images/no-image.png')"
            :id="uuid"
            alt=""
            style="height: 42px; margin: auto; display: block"
            srcset=""
          />
        </a>
      </div>
    </span>

    <input
      type="file"
      :name="`mawj-uploader-${uuid}`"
      @input="imageAdded($event, uuid)"
      :id="`mawj-uploader-${uuid}`"
      style="display: none"
    />

    <label class="mawj-file-input" :for="`mawj-uploader-${uuid}`">
      <i class="ri-upload-2-fill"></i>
      <span style="padding-left: 4px">
        <span v-if="loading">Uploading...</span>
        <span v-else>Tab to upload </span>
      </span>
    </label>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import { getFormData } from "@/utils/createForm";
export default {
  props: ["urlLink", "api", "uploadKey", "formData", "putKey"],
  data() {
    return {
      uuid: uuidv4(),
      enableUpload: false,
      file: null,
      url: null,
      loading: false,
    };
  },
  watch: {
    urlLink: {
      immediate: true,
      handler(val) {
        this.url = val;
      },
    },
  },
  methods: {
    imageAdded($event, uuid) {
      try {
        const file = $event.target.files[0];
        this.file = file;
        var reader = new FileReader();
        // reader.onloadend = () => {
        //   this.url = reader.result;
        // };
        reader.readAsDataURL(file);
        this.uploadAttachement();
      } catch (err) {
        console.error(err);
      }
    },
    async uploadAttachement() {
      const api = this.api;
      if (this.putKey) {
        api.id = this.putKey;
      }
      let file = {};
      file[this.uploadKey] = this.file;
      api.data = getFormData({
        ...this.formData,
        ...file,
      });
      this.loading = true;
      try {
        const res = await this.generateMultipartAPI(api);
        if (res) {
          Swal.fire("File", "Uploaded successfully.", "success");
          this.$emit("uploaded");
        }
      } catch {}
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.mawj-uploader {
  max-height: 190px;
  overflow-y: auto;
  display: block;
}
.mawj-file-input {
  border: 1px solid #646c6f;
  display: flex;
  align-items: center;
  max-width: 124px;
  padding: 2px;
  font-size: 12px;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f2f6f8;
  margin-top: 8px;
}
.mawj-file-input:hover {
  background-color: #646c6f;
  color: white;
}
.file-uploader-indicator {
  position: absolute;
  bottom: 4px;
  right: 8px;
}
</style>
