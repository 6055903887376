<template>
  <div>
    <div v-if="loading" class="text-center my-3">Loading...</div>
    <div v-else>
      <table v-if="items.length" class="mawj-custom-table">
        <tr
          class="solid"
          style="cursor: pointer"
          v-for="item in items"
          :key="item._id"
          @click="rowClicked(item)"
        >
          <td style="width: 20%" v-for="col in columns" :key="col">
            {{ item[col] }}
          </td>
        </tr>
      </table>
      <div v-else class="text-center my-3">
        No Project available for this user
      </div>
    </div>
  </div>
</template>

<script>
import { projects } from "@/config/api/projects";

export default {
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  mounted() {
    this.getProjectsUserWorkedOn();
  },
  props: ["data", "columns", "reload"],
  watch: {
    reload(val) {
      if (val) {
        this.getProjectsUserWorkedOn();
      }
    },
  },
  methods: {
    rowClicked(itemChosen) {
      this.$emit("rowSelected", itemChosen);
    },
    getProjectsUserWorkedOn() {
      const api = projects.getProjectsUserWorkedOn;
      api.id = this.data.id;
      api.params = {
        status: this.data.status,
      };
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.items = res.data.projects.map((item) => {
            return {
              ...item,
              _id: item._id,
              name: item.name_en,
              status: item.status_code,
              role: item.team_members[0].role,
              address: `${item.city}, ${item.country}`,
              createdAt: this.moment(item.createdAt).format("LL"),
              approval: item.is_approved ? "Approved" : "Not Approved",
            };
          });
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("reloaded");
        });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
}
.mawj-custom-table tr {
  border: 0;
  display: block;
  margin: 5px;
}
.mawj-custom-table .solid {
  border: 0.125rem #808a8c solid;
  border-radius: 10px;
}
.mawj-custom-table td {
  padding: 5px 12px;
}
</style>
