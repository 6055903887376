<template>
  <div>
    <div class="text-right mb-2" style="margin-top: -18px">
      <MawjBtn label="Add" @click="openAwardModal = true" />
      <awardModal
        @reload="loadInfo"
        :id="userId"
        :open="openAwardModal"
        @hide="
          openAwardModal = false;
          selectedAward = null;
        "
        :selectedAward="selectedAward"
      />
    </div>

    <div v-if="loading">Loading...</div>
    <div v-else>
      <div v-if="awardInfo.length">
        <div
          class="d-flex justify-content-between align-self-center mb-3"
          v-for="item in awardInfo"
          :key="item._id"
        >
          <div>
            <h6 class="mb-0">
              <img
                src="@/assets/images/award.svg"
                class="pr-1"
                alt=""
                srcset=""
              />{{ item.name }}
            </h6>
            <div style="font-size: 12px">
              <p class="mb-0 pl-3">
                {{ item.description }}
              </p>
              <span class="pl-3">
                {{ moment(item.date_received).format("YYYY") }}
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <fileUploader
              :api="awards.userAwardUpdateAdmin"
              :urlLink="[item.award_document]"
              :putKey="item._id"
              uploadKey="file"
              :formData="{}"
              @uploaded="loadInfo"
            />
            <MawjBtn
              label="Edit"
              @click="editAward(item)"
              :noBorder="true"
              class="mt-0 mx-4"
            />
          </div>
        </div>
      </div>
      <div v-else>No Award available for this user</div>
    </div>
  </div>
</template>

<script>
import { awards } from "@/config/api/awards";
import MawjBtn from "@/components/common/mawjBtn.vue";
import fileUploader from "../fileUploader.vue";
import awardModal from "../awardModal.vue";
export default {
  props: ["userId"],
  components: {
    MawjBtn,
    fileUploader,
    awardModal,
  },
  created() {
    this.loadInfo();
  },
  data() {
    return {
      awardInfo: null,
      loading: false,
      openAwardModal: false,
      selectedAward: null,
      awards,
    };
  },
  methods: {
    loadInfo() {
      this.loading = true;
      const api = awards.users.getByUserId;
      api.id = this.userId;
      this.generateAPI(api)
        .then((res) => {
          this.awardInfo = res.data.models;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editAward(item) {
      this.selectedAward = item;
      this.openAwardModal = true;
    },
  },
};
</script>
