<template>
  <div class="user-profile">
    <div class="d-flex justify-content-between">
      <h1 style="font-size: 18px">
        {{ currentUser.first_name }}
        <span class="account-active">Account status: ACTIVE</span>
      </h1>
      <MawjBtn @click="deleteUser" label="Delete" />
    </div>
    <div>
      <blockquote>{{ currentUser.description || "-" }}</blockquote>
    </div>
    <div>
      {{ currentUser.rating }}/10
      <b-form-rating id="rating-inline" inline readonly :value="currentUser.rating" stars="10"></b-form-rating>
    </div>
    <div>
      <MawjBtn @click="verifyEmail" :type="currentUser.is_email_verified ? 'primary' : ''" :label="
        currentUser.is_email_verified ? 'Email verified' : 'Verify email'
      " />
      <MawjBtn @click="toggleExpert" :type="currentUser.is_expert ? 'primary' : ''" :label="
        currentUser.is_expert ? 'Expert' : 'Expert'
      " />
      <MawjBtn @click="updatePrivateStatus" :type="currentUser.is_private ? 'primary' : ''"  :label="currentUser.is_private ? 'Private' : 'Public'" />
      <MawjBtn @click="openPasswordModal = true" :label="updatingPassword ? 'Updating...' : 'Update Password'" />
    </div>
    <PasswordResetModal :open="openPasswordModal" :id="currentUser._id" @hide="openPasswordModal = false" />
  </div>
</template>

<script>
import MawjBtn from "@/components/common/mawjBtn.vue";
import { userMethods } from "@/state/helpers";
import { users } from "@/config/api/users";
import Swal from "sweetalert2";
import PasswordResetModal from "./passwordResetModal.vue";

export default {
  data() {
    return {
      currentUser: null,
      updatingPassword: false,
      openPasswordModal: false,
    };
  },
  components: {
    MawjBtn,
    PasswordResetModal,
  },
  async created() {
    this.currentUser = await this.getUser();
  },
  methods: {
    ...userMethods,
    verifyEmail() {
      const api = users.update;
      api.id = this.currentUser._id;
      api.data = {
        is_email_verified: !this.currentUser.is_email_verified,
      };

      this.generateAPI(api)
        .then(() => {
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    toggleExpert() {
      const api = users.expertRequest.directUpdate;
      api.data = {
        userId: this.currentUser._id,
      };
      this.generateAPI(api)
        .then(() => {
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updatePrivateStatus() {
      const api = users.update;
      api.id = this.currentUser._id;
      api.data = {
        is_private: !this.currentUser.is_private,
      };

      this.generateAPI(api)
        .then(() => {
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    deleteUser() {
      Swal.fire({
        title: "Delete user",
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          const api = users.delete;
          api.id = this.currentUser._id;
          const res = await this.generateAPI(api);
          console.log(res.data);
          return res;
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "User deleted successfully!",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.user-profile .account-active {
  font-size: 12px;
}

.user-profile .b-rating {
  border: none;
}
</style>

<style>
.b-rating-star-full {
  color: #f7c97a;
}
</style>
