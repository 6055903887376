<template>
  <div class="user-expert-container mb-5">
    <BlockHeader title="Expert" />
    <div class="d-flex justify-content-between mb-2">
      <strong> Profession </strong>
      <span>{{ profession || "Not Provided" }}</span>
    </div>
    <div class="d-flex justify-content-between mb-3">
      <strong> Become an Expert </strong>
      <span> {{ becomeExpert }} </span>
    </div>
    <div v-if="data && data.expert_documents && data.expert_documents.status == 'pending'" class="badge badge-warning">
      There is a expert request is pending with account
    </div>
    <div v-for="r, i in requests" :key="i" class="row">
      <div class="col-xl-6">
        <a :href="r.expert_documents.profession_document" target="_blank">View Doc</a>
      </div>
      <div class="col-xl-6">
        <button class="action-btn" @click.stop.prevent="declineRequest(r._id)">
          <i class="ri-delete-bin-line"></i>
        </button>
        <button class="action-btn" @click.stop.prevent="acceptRequest(r._id)">
          <i class="ri-check-fill"></i>
        </button>
      </div>
    </div>
    <FileUploader @uploaded="$emit('reload')" uploadKey="profession_document" :formData="{ userId: data._id }"
      :urlLink="[expertDocuments]" :api="users.becomeAnExpertFromAdmin" class="m-auto d-block" style="width: 120px" />
  </div>
</template>

<script>
import BlockHeader from "./blockHeader.vue";
import FileUploader from "./fileUploader.vue";
import { users } from "@/config/api/users";
import ExpertTable from "@/views/pages/dashboard/component/expert-table.vue";

export default {
  components: {
    BlockHeader,
    FileUploader, ExpertTable
  },
  props: ["data"],
  mounted() {
    if (this.data)
      this.getPendingDocs();
  },
  data() {
    return {
      users,
      requests: []
    };
  },
  methods: {
    acceptRequest(data) {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to accept this expert request.`,
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            console.log(data);
            const api = users.expertRequest.accept;
            api.id = data;

            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Expert request accepted successfully!", {
                  title: "Accept",
                  variant: "success",
                  solid: true,
                });
                this.$emit("loadPendingExperts");
              })
              .catch((err) => {
                this.$bvToast.toast("Expert request is not accepted", {
                  title: "Accept",
                  variant: "danger",
                  solid: true,
                });
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    declineRequest(data) {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to decline this expert request.`,
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            console.log(data);
            const api = users.expertRequest.decline;
            api.id = data;

            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Expert request declined successfully!", {
                  title: "Decline",
                  variant: "success",
                  solid: true,
                });
                this.$emit("loadPendingExperts");
              })
              .catch((err) => {
                this.$bvToast.toast("Expert request is not declined", {
                  title: "Decline",
                  variant: "danger",
                  solid: true,
                });
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPendingDocs() {
      let api = {
        url: "/expertrequest/pendingOfUser/",
        method: "GET",
        id: null,
      }
      api.id = (this.data && this.data._id)
      this.generateAPI(api).then((result) => {
        this.requests = (result.data.requests)
      }).catch((err) => {
        console.log(err)

      });
    }
  },
  computed: {
    profession() {
      if (this.data) {
        return this.data.sub_profession ? this.data.sub_profession.name : "";
      }

      return "";
    },
    becomeExpert() {
      if (this.data) {
        return this.data.sub_profession
          ? this.moment(this.data.sub_profession.createdAt).format("LL")
          : "";
      }

      return "N/A";
    },
    expertDocuments() {
      return (
        (this.data &&
          this.data.expert_documents &&
          this.data.expert_documents.profession_document) ||
        null
      );
    },

  },
};
</script>

<style>
.user-expert-container {
  font-size: 12px;
  width: 184px;
}
</style>
