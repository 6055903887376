<template>
  <div>
    <div v-if="loading">Loading...</div>
    <div v-else style="max-height:680px;overflow-y:auto">
      <table v-if="timeline">
        <tr v-for="item in timeline" :key="item.index">
          <td class="pr-5">
            {{ item.label }}
          </td>
          <td>
            {{ moment(item.time).format("LL") }}
          </td>
        </tr>
      </table>
      <div v-else>No Time line Data available</div>
    </div>
  </div>
</template>

<script>
import { users } from "@/config/api/users";
import moment from "moment";
export default {
  props: ["user_id"],
  data() {
    return {
      timeline: null,
      loading: false,
    };
  },
  created() {
    this.loadTimelineDetails();
  },
  methods: {
    loadTimelineDetails() {
      const api = users.timeline;

      api.id = this.user_id;
      this.loading = true;
      this.timeline = [];
      let index = 0;
      this.generateAPI(api)
        .then((res) => {
          for (const [key, value] of Object.entries(res.data.userTimeline)) {
            this.timeline.push({
              label: key.replace('_',' '),
              time: value,
              index,
            });
            index += 1;
          }
          console.log("this timeline", this.timeline);
        })
        .catch((err) => {
            // 
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
