<template>
  <b-modal
    id="award-modal"
    hide-footer
    @hidden="$emit('hide')"
    :title="isUpdate ? 'Update award' : 'Create award'"
  >
    <div class="p-2">
      <FormulateForm :values="formValues" @submit="submitAwardData">
        <div class="row">
          <div class="col-md-6">
            <FormulateInput
              type="text"
              name="name"
              label="Name"
              validation="required"
            />
          </div>
          <div class="col-md-6">
            <FormulateInput type="text" name="name_ar" label="Name Arabic" />
          </div>
        </div>

        <FormulateInput
          type="textarea"
          name="description"
          label="Description"
        />
        <a
          class="award_doc_file"
          v-if="isUpdate"
          :href="selectedAward.award_document"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>Please click here to view file</div>
          
          <img
            :src="selectedAward.award_document"
            alt=""
            srcset=""
          />
        </a>

        <FormulateInput
          type="file"
          name="award_document"
          label="Award document"
          validation="required"
          :uploader="(item) => item"
        />
        <div class="row">
          <div class="col-md-6">
            <FormulateInput type="text" name="given_by" label="Given by" />
          </div>
          <div class="col-md-6">
            <FormulateInput
              type="date"
              name="date_received"
              label="Date received"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <FormulateInput
              type="checkbox"
              name="is_verified"
              label="Is verified"
            />
          </div>
        </div>

        <div class="text-right my-2 mb-3">
          <FormulateInput
            type="submit"
            :label="
              sending
                ? isUpdate
                  ? 'Updating...'
                  : 'Adding...'
                : isUpdate
                ? 'Update award'
                : 'Add award'
            "
          />
        </div>
      </FormulateForm>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { awards } from "@/config/api/awards";
import { getFormData } from "@/utils/createForm";
export default {
  props: ["open", "id", "selectedAward"],
  watch: {
    open(val) {
      if (val) {
        this.$bvModal.show("award-modal");
      }
    },
    selectedAward(val) {
      if (val) {
        this.isUpdate = true;
        this.formValues = {
          ...val,
          date_received: this.moment(val.date_received).format("YYYY-MM-DD"),
          award_document: [
            {
              url: val.award_document,
            },
          ],
        };
      } else {
        this.isUpdate = false;
      }
    },
  },
  data() {
    return {
      formValues: {
        is_verified: true,
      },
      sending: false,
      isUpdate: false,
    };
  },

  methods: {
    submitAwardData(val) {
      this.isUpdate ? this.updateAward(val) : this.createAward(val);
    },
    createAward(val) {
      const api = awards.userAwardCreateAdmin;
      const file = val.award_document[0];
      delete val.award_document;
      api.data = getFormData({
        user_id: this.id,
        ...val,
        file,
      });

      this.sending = true;
      this.generateMultipartAPI(api)
        .then(() => {
          Swal.fire("Award", "Award added to user account.", "success");
          this.$bvModal.hide("award-modal");
          this.$emit("reload");
        })
        .catch((err) => {
          Swal.fire(
            "Award",
            `Award is not added, ${err.response.data.message}!`,
            "error"
          );
        })
        .finally(() => {
          this.sending = false;
        });
    },
    updateAward(val) {
      const api = awards.userAwardUpdateAdmin;
      console.log(val);
      api.id = this.selectedAward._id;
      const file = val.award_document[0].url ? null : val.award_document[0];
      delete val.award_document;
      api.data = getFormData({
        name: val.name,
        name_ar: val.name_ar,
        description: val.description,
        date_received: val.date_received,
        given_by: val.given_by,
        is_verified: val.is_verified,
        file,
      });

      this.sending = true;
      this.generateMultipartAPI(api)
        .then(() => {
          Swal.fire("Award", "Award updated successfully.", "success");
          this.$bvModal.hide("award-modal");
          this.$emit("reload");
        })
        .catch((err) => {
          Swal.fire(
            "Award",
            `Award is not updated, ${err.response.data.message}!`,
            "error"
          );
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
};
</script>

