<template>
  <h4 :style="{borderColor: borderColor?borderColor:'#dfdfe1'}">
    <span>
        {{ title }}
    </span>
   
  </h4>
</template>

<script>
export default {
  props: ["title","borderColor"],
};
</script>

<style scoped>
h4{
    border-bottom: 2px solid ;
    font-size: 16px;
    font-weight: 500;
}
h4 span{
    border-bottom: 3px solid #707070;
}
</style>
