<template>
  <div class="social-link-edit">
    <div class="d-flex justify-content-between" v-if="!edit">
      <span> Only 3 can be display </span>
      <MawjBtn :noBorder="true" @click="buttonClicked" label="Edit" />
    </div>

    <FormulateInput
      type="checkbox"
      v-if="edit"
      label="Select 3 social media link to display"
      validation="max:3"
      v-model="selectedSocialMedia"
      :options="['instagram', 'linkedin', 'facebook', 'behance', 'twitter','youtube']"
    />
    <div class="text-right mb-5" style="margin-top: -28px" v-if="edit">
      <MawjBtn
        @click="updateSocialLink"
        :label="updatingDisplayLink ? 'Updating...' : 'Update'"
      />
    </div>
    <hr v-if="edit" class="mb-5" />

    <FormulateForm @submit="updateSocialMediaLink">
      <table>
        <tr>
          <td class="pr-4 d-flex align-items-center">
            <div
              class="social-media-active-indicator"
              :class="selectedSocialMedia.includes('linkedin') ? 'active' : ''"
            ></div>
            Linkedin
          </td>
          <td>
            <FormulateInput
              v-if="edit"
              type="text"
              v-model="formValues.linkedin_url"
            />

            <span v-else>
              <a
                v-if="userData.linkedin_url"
                :href="userData.linkedin_url"
                target="_blank"
              >
                {{ userData.linkedin_url }}</a
              >
              <span v-else> Not Provided </span>
            </span>
          </td>
        </tr>
        <tr>
          <td class="pr-4 d-flex align-items-cente">
            <div
              class="social-media-active-indicator"
              :class="selectedSocialMedia.includes('instagram') ? 'active' : ''"
            ></div>
            Instagram
          </td>
          <td>
            <FormulateInput
              v-if="edit"
              type="text"
              v-model="formValues.instagram_url"
            />
            <span v-else>
              <a
                v-if="userData.instagram_url"
                :href="userData.instagram_url"
                target="_blank"
              >
                {{ userData.instagram_url }}</a
              >
              <span v-else> Not Provided </span>
            </span>
          </td>
        </tr>
        <tr>
          <td class="pr-4 d-flex align-items-cente">
            <div
              class="social-media-active-indicator"
              :class="selectedSocialMedia.includes('facebook') ? 'active' : ''"
            ></div>
            Facebook
          </td>
          <td>
            <FormulateInput
              v-if="edit"
              type="text"
              v-model="formValues.facebook_url"
            />
            <span v-else>
              <a
                v-if="userData.facebook_url"
                :href="userData.facebook_url"
                target="_blank"
              >
                {{ userData.facebook_url }}</a
              >
              <span v-else> Not Provided </span>
            </span>
          </td>
        </tr>
        <tr>
          <td class="pr-4 d-flex align-items-cente">
            <div
              class="social-media-active-indicator"
              :class="selectedSocialMedia.includes('twitter') ? 'active' : ''"
            ></div>
            Twitter
          </td>
          <td>
            <FormulateInput
              v-if="edit"
              type="text"
              v-model="formValues.twitter_url"
            />
            <span v-else>
              <a
                v-if="userData.twitter_url"
                :href="userData.twitter_url"
                target="_blank"
              >
                {{ userData.twitter_url }}</a
              >
              <span v-else> Not Provided </span>
            </span>
          </td>
        </tr>
        <tr>
          <td class="pr-4 d-flex align-items-cente">
            <div
              class="social-media-active-indicator"
              :class="selectedSocialMedia.includes('youtube') ? 'active' : ''"
            ></div>
            Youtube
          </td>
          <td>
            <FormulateInput
              v-if="edit"
              type="text"
              v-model="formValues.youtube_url"
            />
            <span v-else>
              <a
                v-if="userData.youtube_url"
                :href="userData.youtube_url"
                target="_blank"
              >
                {{ userData.youtube_url }}</a
              >
              <span v-else> Not Provided </span>
            </span>
          </td>
        </tr>
        <tr>
          <td class="pr-4 d-flex align-items-cente">
            <div
              class="social-media-active-indicator"
              :class="selectedSocialMedia.includes('behance') ? 'active' : ''"
            ></div>
            Behance
          </td>
          <td>
            <FormulateInput
              v-if="edit"
              type="text"
              v-model="formValues.behance_url"
            />
            <span v-else>
              <a
                v-if="userData.behance_url"
                :href="userData.behance_url"
                target="_blank"
              >
                {{ userData.behance_url }}</a
              >
              <span v-else> Not Provided </span>
            </span>
          </td>
        </tr>
      </table>
      <div class="mt-4" v-if="edit">
        <div class="row">
          <div class="col-6">
            <MawjBtn @click="edit = false" label="Cancel" />
          </div>
          <div class="col-6 text-right">
            <FormulateInput
              type="submit"
              :label="updatingLink ? 'Saving...' : 'Save'"
            />
          </div>
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import MawjBtn from "@/components/common/mawjBtn.vue";
import { users } from "@/config/api/users.js";
import Swal from "sweetalert2";
export default {
  props: ["userData"],
  components: {
    MawjBtn,
  },
  watch: {
    userData(val) {
      this.formValues = {
        linkedin_url: val.linkedin_url,
        instagram_url: val.instagram_url,
        facebook_url: val.facebook_url,
        twitter_url: val.twitter_url,
        youtube_url: val.youtube_url,
        behance_url: val.behance_url,
      };
      this.selectedSocialMedia = val.active_links.map((item) => {
        return item.social_media;
      });
    },
  },
  data() {
    return {
      formValues: {
        linkedin_url: null,
        instagram_url: null,
        facebook_url: null,
        twitter_url: null,
        youtube_url: null,
        behance_url: null,
      },
      edit: false,
      selectedSocialMedia: [],
      updatingLink: false,
      updatingDisplayLink: false,
    };
  },
  methods: {
    buttonClicked() {
      this.edit = true;
    },
    updateSocialLink() {
      const api = users.changeActiveSocialLinks;
      api.id = this.userData._id;
      this.updatingDisplayLink = true;
      api.data = {
        active_social_medias: this.selectedSocialMedia,
      };
      this.generateAPI(api)
        .then((res) => {
          this.$emit("reload");
          Swal.fire({
            title: "Social Media link display updated.",
          });
        })
        .catch((err) => {
          Swal.fire(
             "Social Media link display updated!",
            `${err.response.message}`,
           "error"
          );
        })
        .finally(() => {
          this.updatingDisplayLink = false;
        });
    },
    updateSocialMediaLink() {
      const api = users.update;
      api.id = this.userData._id;
      this.updatingLink = true;
      api.data = this.formValues;
      this.generateAPI(api)
        .then((res) => {
          this.$emit("reload");
          Swal.fire({
            title: "Social Media link updated successfully.",
          });
          this.edit = false;
        })
        .catch((err) => {
          Swal.fire({
            title: "Social Media link is not updated!",
            icon: "error",
          });
        })
        .finally(() => {
          this.updatingLink = false;
        });
    },
  },
};
</script>
<style>
.social-link-edit {
  max-width: 480px;
}
.social-link-edit .formulate-input[data-classification="button"] button {
  padding: 6px;
  min-width: 48px !important;
}
.social-link-edit .formulate-input[data-classification="text"] input {
  width: 280px;
}
.social-media-active-indicator {
  width: 18px;
  background-color: #fff;
  height: 14px;
  border-radius: 12px;
  border: 1px solid #eceeee;
  display: inline-block;
  margin: 2px;
  margin-right: 4px;
}
.social-media-active-indicator.active {
  background-color: rgb(88, 110, 132);
}
</style>
