<template>
  <router-link :to="`/user-info-v2?id=${expert.user && expert.user._id}`">
    <div class="row project-custom-table">
      <div class="col-xl-4">
        {{ expert.user && expert.user.first_name }}
      </div>
      <div class="col-xl-3">Email: {{ expert.user && expert.user.email }}</div>

      <div class="col-xl-3">
        Created On : {{ moment(expert.createdAt).format("LL") }}
      </div>
      <div class="col-xl-2">
        <button
          class="action-btn"
          @click.stop.prevent="declineRequest(expert._id)"
        >
          <i class="ri-delete-bin-line"></i>
        </button>
        <button
          class="action-btn"
          @click.stop.prevent="acceptRequest(expert._id)"
        >
          <i class="ri-check-fill"></i>
        </button>
      </div>
    </div>
  </router-link>
</template>

<script>
import { users } from "@/config/api/users";
export default {
  data() {
    return {};
  },

  props: ["expert"],
  methods: {
    acceptRequest(data) {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to accept this expert request.`,
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            console.log(data);
            const api = users.expertRequest.accept;
            api.id = data;

            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Expert request accepted successfully!", {
                  title: "Accept",
                  variant: "success",
                  solid: true,
                });
                this.$emit("loadPendingExperts");
              })
              .catch((err) => {
                this.$bvToast.toast("Expert request is not accepted", {
                  title: "Accept",
                  variant: "danger",
                  solid: true,
                });
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    declineRequest(data) {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to decline this expert request.`,
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            console.log(data);
            const api = users.expertRequest.decline;
            api.id = data;

            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Expert request declined successfully!", {
                  title: "Decline",
                  variant: "success",
                  solid: true,
                });
                this.$emit("loadPendingExperts");
              })
              .catch((err) => {
                this.$bvToast.toast("Expert request is not declined", {
                  title: "Decline",
                  variant: "danger",
                  solid: true,
                });
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style>
.project-custom-table {
  margin: 10px 0px;
  padding: 20px 10px;
  border-radius: 5px;
  color: #000;
  background-color: #fff;
}
</style>
