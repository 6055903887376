<template>
  <b-modal
    id="password-reset-modal"
    hide-footer
    @hidden="$emit('hide')"
    title="Password Reset"
  >
    <div>
      <FormulateForm :values="formValues" @submit="submitPassword">
        <FormulateInput
          type="password"
          name="password"
          label="Enter new Password"
          validation="required"
        />
        <FormulateInput
          type="password"
          name="password_confirm"
          label="Confirm password"
          validation="required|confirm:password"
          validationName="Password confirmation"
        />

        <div class="text-right">
          <FormulateInput
            type="submit"
            :label="sending ? 'Updating...' : 'Update Password'"
          />
        </div>
      </FormulateForm>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { users } from "@/config/api/users";
export default {
  props: ["open", "id"],
  watch: {
    open(val) {
      if (val) {
        this.$bvModal.show("password-reset-modal");
      }
    },
  },
  data() {
    return {
      formValues: {
        password: null,
        password_confirm: null,
      },
      sending: false,
    };
  },
  methods: {
    submitPassword(val) {
      const api = users.changeUserPassword;
      api.data = {
        user_id: this.id,
        new_password: val.password,
      };
      this.sending = true;

      this.generateAPI(api)
        .then(() => {
          Swal.fire("Password", "Password resetted successfully.", "success");
          this.$bvModal.hide("password-reset-modal");
        })
        .catch((err) => {
          Swal.fire(
            "Password",
            `Password is not, ${err.response.data.message}!`,
            "error"
          );
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
};
</script>
