<template>
  <b-modal
    id="certificate-modal"
    hide-footer
    @hidden="$emit('hide')"
    :title="updateData ? 'Update certificate' : 'Add certificate'"
  >
    <div>
      <FormulateForm :values="formValues" @submit="submitCertificate">
        <div class="row">
          <div class="col-sm-6">
            <FormulateInput
              type="text"
              name="title"
              label="Title"
              validation="required"
            />
          </div>
          <div class="col-sm-6">
            <FormulateInput type="text" name="title_ar" label="Title arabic" />
          </div>
        </div>
        <FormulateInput
          type="textarea"
          name="description"
          label="Title arabic"
        />
        <FormulateInput
          type="date"
          name="date_received"
          label="Date received"
        />
        <a
          class="award_doc_file"
          v-if="updateData"
          :href="selectedCertificate.certificate_document"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>Please click here to view file</div>

          <img
            :src="selectedCertificate.certificate_document"
            alt=""
            srcset=""
          />
        </a>

        <FormulateInput
          type="file"
          name="certificate_document"
          :uploader="(item) => item"
          label="Please choose certificate document"
          validation="required"
        />
        <div class="text-right">
          <FormulateInput
            type="submit"
            :label="
              sending
                ? updateData
                  ? 'Updating..'
                  : 'Sending...'
                : updateData
                ? 'Update Certificate'
                : 'Add Certificate'
            "
          />
        </div>
      </FormulateForm>
    </div>
  </b-modal>
</template>

<script>
import { certificates } from "@/config/api/certificates";
import { getFormData } from "@/utils/createForm";

export default {
  props: ["open", "id", "selectedCertificate"],
  watch: {
    open(val) {
      if (val) {
        this.$bvModal.show("certificate-modal");
      }
    },
    selectedCertificate(val) {
      if (val) {
        this.formValues = {
          title: val.title,
          title_ar: val.title_ar,
          description: val.description,
          date_received: this.moment(val.date_received).format("YYYY-MM-DD"),
          certificate_document: [{ url: val.certificate_document }],
        };
        this.updateData = true;
      } else {
        this.updateData = false;
      }
    },
  },
  data() {
    return {
      formValues: {
        title: null,
        certificate_document: null,
      },
      updateData: false,
      sending: false,
    };
  },
  methods: {
    submitCertificate(val) {
      this.updateData ? this.updateCertificate(val) : this.addCertificate(val);
    },
    addCertificate(val) {
      const certificate_document = val.certificate_document[0];
      delete val.certificate_document;
      const data = {
        ...val,
        user: this.id,
        certificate_document,
      };
      const api = certificates.addUserCertificateAdmin;

      api.data = getFormData(data);
      this.sending = true;
      this.generateMultipartAPI(api)
        .then(() => {
          this.$bvToast.toast("Certificate is added!", {
            title: "Certificate",
            variant: "success",
            solid: true,
          });
          this.$emit("reload");
          this.$bvModal.hide("certificate-modal");
        })
        .catch((err) => {
          this.$bvToast.toast("Certificate is not added!", {
            title: "Certificate",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.sending = false;
        });
    },
    updateCertificate(val) {
      const certificate_document = val.certificate_document[0].url
        ? val.certificate_document[0]
        : null;
      delete val.certificate_document;
      const data = {
        ...val,
        certificate_document,
      };
      const api = certificates.updateUserCertificateAdmin;
      api.id = this.selectedCertificate._id;
      api.data = getFormData(data);
      this.sending = true;
      this.generateMultipartAPI(api)
        .then(() => {
          this.$bvToast.toast("Certificate is updated!", {
            title: "Certificate",
            variant: "success",
            solid: true,
          });
          this.$emit("reload");
          this.$bvModal.hide("certificate-modal");
        })
        .catch((err) => {
          this.$bvToast.toast("Certificate is not updated", {
            title: "Certificate",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
};
</script>
