<template>
  <b-modal
    @hide="
      edit = false;
      $emit('close');
    "
    id="user-project-modal"
    title="Project Details"
    hide-footer
  >
    <div v-if="userData">
      <div
        class="d-flex justify-content-between"
        style="margin-top: -18px; margin-bottom: 28px"
      >
        <span> {{ userData.name }} </span>

        <span>{{ userData.address }}</span>
        <span>
          {{ userData.status }}
        </span>
        <span>
          {{ userData.approval }}
        </span>
      </div>

      <div>
        <blockHeader class="mx-3" title="Claim for the Project" />

        <MawjBtn label="Edit" style="float: right" @click="edit = true" />

        <table
          style="border-collapse: separate; border-spacing: 1em 1em"
          v-if="!edit"
        >
          <tr>
            <td class="font-weight-bold">Role in the project</td>
            <td>{{ userData.role }}</td>
          </tr>
          <!-- <tr class="mb-3">
            <td class="font-weight-bold">
              Worked under which <br />
              company for this project
            </td>
            <td>Al Torath consultancy</td>
          </tr> -->
          <tr class="mb-3">
            <td class="font-weight-bold">Project stage</td>
            <td>{{ getStageData(userData.team_members[0].stages) }}</td>
          </tr>
          <tr class="mb-3">
            <td class="font-weight-bold">
              Year of working for this <br />
              project | Duration
            </td>
            <td>{{ userData.team_members[0].duration }}</td>
          </tr>
          <tr class="mb-3">
            <td class="font-weight-bold">Attachment</td>
            <td>
              <fileUploader
                :api="projects.editClaimProject"
                :urlLink="userData.team_members[0].support_documents"
                uploadKey="file"
                :formData="{
                  claim_id: userData.team_members[0]._id,
                  project_id: this.userData._id,
                }"
                @uploaded="closeModal"
              />
            </td>
          </tr>
          <!-- <tr class="mb-3">
            <td class="font-weight-bold">Comment</td>
            <td>
              I worked in design team then i moved<br />
              to another department for better exposure.
            </td>
          </tr> -->
        </table>
        <div v-else>
          <projectDetailsForm
            @reload="$emit('reload')"
            :companiesData="companiesData"
            :stages="stages"
            :userData="userData"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import fileUploader from "./fileUploader.vue";
import blockHeader from "./blockHeader.vue";
import MawjBtn from "@/components/common/mawjBtn.vue";
import projectDetailsForm from "./projectDetailsForm.vue";
import { projects } from "../../config/api/projects";

export default {
  components: {
    fileUploader,
    blockHeader,
    MawjBtn,
    projectDetailsForm,
  },
  data() {
    return {
      userData: null,
      edit: false,
      projects,
    };
  },
  props: ["projectData", "id", "companiesData", "stages"],
  watch: {
    projectData(val) {
      if (val) {
        this.userData = val;
        this.$bvModal.show("user-project-modal");
      }
    },
  },
  methods: {
    getStageData(data) {
      let stages = "";
      for (let i = 0; i < data.length; i++) {
        stages += this.stages.find((item) => item._id === data[i]).name + ", ";
      }

      return stages.slice(0, -2);
    },
    closeModal() {
      this.edit = false;
      this.$emit('reload')
      this.$bvModal.hide("user-project-modal");
      this.$emit("close");
    },
  },
};
</script>

<style>
#user-project-modal .modal-header {
  border-bottom: none !important;
}
</style>
