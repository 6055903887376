<template>
  <div class="project-details-tab">
    <div class="row">
      <div class="col-9">
        <div class="row">
          <div class="col-10">
            <BlockHeader title="Projects" borderColor="white" />
            <div class="mawj-custom-tab">
              <b-tabs pills card v-model="tabIndex">
                <b-tab title="All" active
                  ><projectTable
                    v-if="tabIndex === 0"
                    :reload="reload"
                    @reloaded="reload = false"
                    :data="{ id: data._id }"
                    v-on:rowSelected="rowSelected"
                    :columns="columns"
                /></b-tab>
                <b-tab title="Approved"
                  ><projectTable
                    :reload="reload"
                    @reloaded="reload = false"
                    v-if="tabIndex === 1"
                    :data="{ id: data._id, status: 'Approved' }"
                    v-on:rowSelected="rowSelected"
                    :columns="columns"
                /></b-tab>
                <b-tab title="Pending"
                  ><projectTable
                    :reload="reload"
                    @reloaded="reload = false"
                    v-if="tabIndex === 2"
                    :data="{ id: data._id, status: 'Pending' }"
                    v-on:rowSelected="rowSelected"
                    :columns="columns"
                /></b-tab>
                <b-tab title="Rejected"
                  ><projectTable
                    :reload="reload"
                    @reloaded="reload = false"
                    v-if="tabIndex === 3"
                    :data="{ id: data._id, status: 'Rejected' }"
                    v-on:rowSelected="rowSelected"
                    :columns="columns"
                /></b-tab>
                <b-tab title="Resubmitted"
                  ><projectTable
                    :reload="reload"
                    @reloaded="reload = false"
                    v-if="tabIndex === 4"
                    :data="{ id: data._id, status: 'Resubmitted' }"
                    v-on:rowSelected="rowSelected"
                    :columns="columns"
                /></b-tab>
              </b-tabs>
            </div>
          </div>
          <div class="col-2">
            <!--  -->
          </div>
        </div>
      </div>
    </div>
    <projectDetailsModal
      style="margin-top: 48px; margin-left: -20px"
      :projectData="selectedProject"
      :id="data._id"
      @close="selectedProject = null"
      :companiesData="companiesData"
      :stages="stages"
      @reload="reloadData"
    />
  </div>
</template>

<script>
import BlockHeader from "./blockHeader.vue";
import projectTable from "./projectTable";
import MawjBtn from "@/components/common/mawjBtn.vue";
import projectDetailsModal from "./projectDetailsModal";
import { companies } from "@/config/api/companies";
import { projects } from "@/config/api/projects";
export default {
  props: ["data"],
  components: {
    BlockHeader,
    projectTable,
    MawjBtn,
    projectDetailsModal,
  },
  created() {
    this.loadAllCompanies();
    this.loadAllStages();
  },
  data() {
    return {
      selectedProject: null,
      tabIndex: 0,
      companiesData: [],
      stageData: [],
      stages: [],
      reload: false,
      columns: ["name", "address", "status", "role", "createdAt", "approval"],
    };
  },
  methods: {
    rowSelected(item) {
      this.selectedProject = item;
    },
    loadAllCompanies() {
      const api = companies.adminGetAllCompanies;

      this.generateAPI(api).then((res) => {
        this.companiesData = res.data.companies;
      });
    },
    loadAllStages() {
      const api = projects.getStages;
      this.generateAPI(api).then((res) => {
        this.stages = res.data.stages;
      });
    },
    reloadData() {
      this.reload = true;
    },
  },
};
</script>
<style>
.project-details-tab .tab-pane {
  padding: 0px;
}
</style>
